/**
 * tags
 */

import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Const from '../const'
// components
import Header from '../components/Header'
import List from '../components/List'
import { Container, Layout, LayoutMain, LayoutLeft, LayoutAside } from '../components/Layout'

const TagsPage = ({ data, pageContext }) => {
  const { edges = [] } = data.allMarkdownRemark

  return (
    <Container>
      <Helmet>
        <title>タグ「{ pageContext.tag }」の検索結果 | { Const.SITE_NAME }</title>
      </Helmet>

      <Header />

      <Layout>
        <LayoutLeft />

        <LayoutMain>
          <h1>{ `${ pageContext.tag }` }</h1>
          <List items={ edges } />
        </LayoutMain>

        <LayoutAside />
      </Layout>
    </Container>
  )
}

export const pageQuery = graphql`
query($tag: String) {
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: { frontmatter: { active: { eq: 1 }, tags: { in: [ $tag ] } } }
    limit: 2000
  ) {
    totalCount
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          tags
        }
      }
    }
  }
}
`

export default TagsPage
